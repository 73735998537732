































































import { Component, Vue } from 'vue-property-decorator';
import SideBySide from './Layout/SideBySide.vue';

@Component({
  components: {
    SideBySide,
  },
})
export default class Our4StepProcess extends Vue {

}
